import Api from '@api';

import { YN, VMI_STATUS, PAGER, VMI_TYPE } from '@constant';

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        "key": "VMI_ID",
        "label": "Transfer ID",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入Transfer ID",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "VMI_TYPE",
        "label": "类型",
        "type": "select",
        "placeholder": "请选择类型",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": VMI_TYPE,
        "formatter": ({ cellValue, row, column }) => {
          let res = VMI_TYPE.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "STATUS",
        "label": "状态 ",
        "type": "select",
        "placeholder": "请选择状态 ",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": VMI_STATUS,
        "formatter": ({ cellValue, row, column }) => {
          let res = VMI_STATUS.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "NOTES",
        "label": "注释",
        "type": "textarea",
        "placeholder": "请输入注释",
        "tabGroup": "THead",
        "tabGroupName": "表头",
        "showBlock": [
          "all"
        ],
        "col": "2",
        "checked": false
      },
      {
        "key": "CLIENT_ID",
        "label": "客户端",
        "type": "magnifier",
        "placeholder": "请选择客户端",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        defaultUpper: true,
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "SKU_ID",
        "label": "SKU",
        "type": "magnifier",
        "placeholder": "请输入SKU",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CONDITION_ID",
        "label": "条件",
        "type": "magnifier",
        "placeholder": "请输入条件",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "_DESCRIPTION_",
        "label": "描述",
        "type": "textarea",
        "placeholder": "",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "dataInfo"
        ],
        "col": "2",
      },
      {
        "key": "SITE_ID",
        "label": "站点",
        "type": "select",
        "placeholder": "请选择站点",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType: 'SINGLE_VALUE',
        options: async () => {
          let { status, data: { list }, message } = await Api.setSelectSiteList({
            "SITE": {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.SITE_ID,
                label: item.SITE_ID
              }
            })
          }
        },
      },
      {
        "key": "OTHER_CLIENT_ID",
        "label": "其他客户端",
        "type": "select",
        "placeholder": "请输入其他客户端",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        valueType: 'SINGLE_VALUE',
        "options": async () => {
          let { status, data: { list }, message } = await Api.setSelectClientList({
            WORKSTATION: {},
            ...defaultPaginationInfo
          });

          if (Number(status) === 200) {
            return list.map(item => {
              return {
                key: item.CLIENT_ID,
                label: item.CLIENT_ID
              }
            })
          }
        }
      },
      {
        "key": "OWNER_ID",
        "label": "所有者",
        "type": "magnifier",
        "placeholder": "请选择所有者",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        // valueType:'SINGLE_VALUE',
        // options: async () => {
        //   let {status, data: {list}, message} = await Api.setSelectOwnerList({
        //     WORKSTATION: {},
        //     ...defaultPaginationInfo
        //   });
        //
        //   if (Number(status) === 200) {
        //     return list.map(item=>{
        //       return{
        //         key:item.OWNER_ID,
        //         label:item.OWNER_ID
        //       }
        //     })
        //   }
        // },
      },
      {
        "key": "FROM_ORIGIN_ID",
        "label": "从产地",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入从产地",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "REASON_ID",
        "label": "原因代码",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入原因代码",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "VMI_QTY",
        "label": "数量",
        "type": "numrange",
        "placeholder": "请选择数量",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "UPDATE_SKU_ATTRIBUTES",
        "label": "更新商品属性",
        "type": "select",
        "placeholder": "请选择更新商品属性",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "GENERATE_NEW_TAGS",
        "label": "生成新TAG",
        "type": "select",
        "placeholder": "请选择生成新TAG",
        "tabGroup": "mqs_vmitransfer_tab0",
        "tabGroupName": "综合",
        "showBlock": [
          "all"
        ],
        "checked": false,
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      },
      {
        "key": "CREATED_BY",
        "label": "创建人 ",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入创建人 ",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATION_DATE",
        "label": "创建日期 ",
        "type": "date",
        "placeholder": "请选择创建日期 ",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "CREATION_TIME",
        "label": "创建时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择创建时间",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATED_BY",
        "label": "最后更新人",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入最后更新人",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATE_DATE",
        "label": "最后更新日期",
        "type": "date",
        "placeholder": "请选择最后更新日期",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "all"
        ],
        "checked": false
      },
      {
        "key": "LAST_UPDATE_TIME",
        "label": "最后更新时间",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择最后更新时间",
        "tabGroup": "mqs_vmitransfer_tab1",
        "tabGroupName": "杂项",
        "showBlock": [
          "dataInfo"
        ],
        "checked": false
      },
      {
        "key": "USER_DEF_TYPE_1",
        "label": "用户自定义类型 1 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_2",
        "label": "用户自定义类型 2 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 2 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_3",
        "label": "用户自定义类型 3 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 3 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_4",
        "label": "用户自定义类型 4 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 4 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_5",
        "label": "用户自定义类型 5 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 5 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_6",
        "label": "用户自定义类型 6 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 6 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_7",
        "label": "用户自定义类型 7 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 7 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TYPE_8",
        "label": "用户自定义类型 8 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义类型 8 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_CHK_1",
        "label": "用户自定义的检查1 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_2",
        "label": "用户自定义的检查2 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查2 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_3",
        "label": "用户自定义的检查3 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查3 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_CHK_4",
        "label": "用户自定义的检查4 ",
        "type": "select",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义的检查4 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head",
        "options": YN,
        "formatter": ({ cellValue, row, column }) => {
          let res = YN.filter(item => {
            return item.key === cellValue
          })
          return res[0] ? res[0].label : ''
        }
      }, {
        "key": "USER_DEF_DATE_1",
        "label": "用户自定义日期 1 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_1",
        "label": "用户自定义时间 1 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_2",
        "label": "用户自定义日期 2 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 2 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_2",
        "label": "用户自定义时间 2 ",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 2",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_3",
        "label": "用户自定义日期 3 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 3 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_3",
        "label": "用户自定义时间 3",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 3",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_DATE_4",
        "label": "用户自定义日期 4 ",
        "type": "date",
        "placeholder": "请选择用户自定义日期 4 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_TIME_4",
        "label": "用户自定义时间 4",
        "type": "time",
        "props": {
          "value-format": "HH:mm:ss"
        },
        "placeholder": "请选择用户自定义时间 4",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["all"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_1",
        "label": "用户自定义号码 1 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_2",
        "label": "用户自定义号码 2",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 2",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_3",
        "label": "用户自定义号码 3 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 3 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NUM_4",
        "label": "用户自定义号码 4 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义号码 4 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_1",
        "label": "用户自定义注释 1 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义注释 1 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      }, {
        "key": "USER_DEF_NOTE_2",
        "label": "用户自定义注释 2 ",
        "type": "magnifier",
        "tableName": 'UPI_HEADER',
        "placeholder": "请输入用户自定义注释 2 ",
        "tabGroup": "user_def_vmit_tab0",
        "tabGroupName": "用户自定义的_设置1",
        "showBlock": ["dataInfo"],
        "checked": false,
        "sheetName": "head"
      },
      {
        "key": "CE_VMI_TYPE",
        "label": "",
        "type": "magnifier",
        "tableName": 'VMI_TRANSFER',
        "placeholder": "请输入",
        "showBlock": [],
        "checked": false
      }
    ]
  }
}